import React from 'react'
import { ReactSlider } from '../components/slider'
import { Features } from '../components/features'
import { Border } from '../components/header'
import { Products } from '../components/products'
import { ServiceModal } from '../components/serviceModal'
import '../App.css'

export const Home = () => {
  return (
    <div id='home'>
      <ReactSlider />
      <Features />
      <Border />
      <Products />
      <Border />
      <ServiceModal />
    </div>
  )
}
