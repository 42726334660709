import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  background: #f7f7f7;
  padding: 1% 0%;

  .copyright-text {
    margin-top: 20px;
    font-size: 12px;
    a {
      color: #000;
    }
  }
`

const FooterList = styled.li`
  display: inline-block;
  margin: 0 10px;
  a {
    color: #333;
    font-size: 12px;
  }
`

const SocialMedia = styled.ul`
  li {
    display: inline-block;
    margin: 0 5px;
    a {
      padding: 8px 10px;
      i {
        font-size: 20px;
        color: green;
      }
    }
  }
`

export const Footer = () => {
  return (
    <StyledFooter className='text-center' id='footer'>
      <div className='row'>
        <div className='col-md-12'>
          <SocialMedia>
            <li>
              <a href='mailto:xyz@denebmithran@gmail.com'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='30'
                  height='30'
                  viewBox='0 0 512 512'
                >
                  <path
                    fill='currentColor'
                    d='M424 80H88a56.06 56.06 0 0 0-56 56v240a56.06 56.06 0 0 0 56 56h336a56.06 56.06 0 0 0 56-56V136a56.06 56.06 0 0 0-56-56Zm-14.18 92.63l-144 112a16 16 0 0 1-19.64 0l-144-112a16 16 0 1 1 19.64-25.26L256 251.73l134.18-104.36a16 16 0 0 1 19.64 25.26Z'
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/a-chakkaravarthy-andiappan-45998664/?originalSubdomain=in'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='30'
                  height='30'
                  viewBox='0 0 512 512'
                >
                  <path
                    fill='currentColor'
                    d='M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32Zm-273.3 373.43h-64.18V205.88h64.18ZM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43c0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43Zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44c-17.74 0-28.24 12-32.91 23.69c-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44c42.13 0 74 27.77 74 87.64Z'
                  />
                </svg>
              </a>
            </li>
          </SocialMedia>
          <ul className='mt-30px text-uppercase'>
            <FooterList>
              <a href='contact.html'>CONTACT</a>
            </FooterList>
            <FooterList>
              <a href='shop.html'>SHOP</a>
            </FooterList>
            <FooterList>
              <a href='contact.html'>PRIVACY POLICY</a>
            </FooterList>
          </ul>
          <p className='copyright-text'>Copyright &copy;2024, Developed by Den Zip Corp</p>
        </div>
      </div>
    </StyledFooter>
  )
}
