import { useState } from 'react'
import { css, styled } from 'styled-components'
import { motion } from 'framer-motion'
import ColorIcon from '../images/products/color-circle.webp'
import tw from 'twin.macro'
import image1 from '../images/denzip/white-zipper-4.jpg'
import image3 from '../images/denzip/painted-autolock.jpg'
import image2 from '../images/denzip/den.webp'

export const Container = styled.div`
  ${tw`relative`}
`
export const ContentWithPaddingXl = styled.div`
  ${tw`py-5 flex flex-col lg:w-1/2 mx-auto`}
`

const HeaderRow = styled.div`
  ${tw`flex justify-between items-center flex-col`}
`
const Header = styled.h2`
  ${tw`font-poppins text-3xl sm:text-4xl font-black tracking-wide text-center`}
`
const TabsControl = styled.div`
  ${tw`flex flex-wrap bg-gray-100 rounded leading-none mt-5`}
`

const TabControl = styled.div<{ active: boolean }>`
  ${tw`cursor-pointer px-6 py-3 sm:mt-0 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-cyan-600! text-gray-100!`}
  }
`

const TabContent = styled(motion.div)`
  ${tw`mt-6 flex flex-wrap`}
`
const CardContainer = styled.div`
  ${tw`my-6 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto md:max-w-[15vh] lg:max-w-[28vh]`}
`
const Card = styled(motion.a)`
  ${tw`bg-gray-100 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`}
`
const CardImageContainer = styled.div<{ imageSrc: string }>`
  ${(props) => css`
    background-image: url('${props.imageSrc}');
  `}
  ${tw`h-60 bg-center bg-cover relative rounded-t`}
`
const CardRatingContainer = styled.div`
  ${tw`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`}
`
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`
export const PrimaryButton = styled.button`
  ${tw`px-8 py-3 font-bold rounded text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:outline-none transition duration-300`}
`
const CardButton = styled(PrimaryButton)`text-sm`

// const CardReview = styled.div`
//   ${tw`font-medium text-xs text-gray-600`}
// `;

const CardText = styled.div`
  ${tw`p-4 text-gray-900 font-poppins rounded`}
`
const CardTitle = styled.h5`
  ${tw`text-lg font-semibold group-hover:text-cyan-600`}
`
const CardContent = styled.p`
  ${tw`mt-1 text-sm font-medium text-gray-600`}
`
// const CardPrice = styled.p`
//   ${tw`mt-4 text-xl font-bold`}
// `

export const Products = () => {
  const tabs = {
    AutoLock: [
      {
        title: 'DEN X',
        content: 'Den metallic autolock zipper',
        imageSrc: image1,
        options: [
          {
            size: 10,
            price: 350,
            colors: [],
          },
        ],
        colorsLength: 60,
      },
      {
        title: 'DEN',
        content: 'Den painter autolock zipper',
        imageSrc: image3,
        options: [
          {
            size: 10,
            price: 330,
            colors: [],
          },
        ],
        colorsLength: 5,
      },
    ],
    PinLock: [
      {
        title: 'DEN',
        content: 'Den pinlock standard size puller',
        imageSrc: image2,
        options: [
          {
            size: 10,
            price: 300,
            colors: [],
          },
        ],
        colorsLength: 10,
      },
      {
        title: 'MEN',
        content: 'Den pinlock short length puller',
        imageSrc: image2,
        options: [
          {
            size: 10,
            price: 280,
            colors: [],
          },
        ],
        url: '#',
        colorsLength: 10,
      },
      //   {
      //     title: 'Custom branded',
      //     content: 'Get your own brand name casted in the puller',
      //     imageSrc:
      //       'https://m.media-amazon.com/images/I/5186LSeRNMS._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg',
      //     options: [
      //       {
      //         size: 10,
      //         price: 280,
      //         colors: [],
      //       },
      //     ],
      //     url: '#',
      //     colorsLength: 10,
      //   },
    ],
  }

  const tabsKeys = Object.keys(tabs)
  const [activeTab, setActiveTab] = useState(tabsKeys[0])

  return (
    <Container id='product'>
      <HeaderRow>
        <Header>Products</Header>
        <h5 className='font-semi-bold text-gray-600 text-center mt-3'>
          Checkout our zipper products offered in various colors and sizes
        </h5>
        <TabsControl>
          {Object.keys(tabs).map((tabName, index) => (
            <TabControl
              key={index}
              active={activeTab === tabName}
              onClick={() => setActiveTab(tabName)}
            >
              {tabName}
            </TabControl>
          ))}
        </TabsControl>
      </HeaderRow>
      <ContentWithPaddingXl>
        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: 'flex',
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: 'none',
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? 'current' : 'hidden'}
            animate={activeTab === tabKey ? 'current' : 'hidden'}
          >
            {/*// @ts-ignore */}
            {tabs[tabKey].map((card: any, index: number) => (
              <CardContainer key={index}>
                <Card
                  className='group'
                  href={card.url}
                  initial='rest'
                  whileHover='hover'
                  animate='rest'
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <img src={ColorIcon} className='h-5.5 w-6 pr-1 opacity-50' />
                        {`${card.colorsLength} colors`}
                      </CardRating>
                      {/* <CardReview>({card.reviews})</CardReview> */}
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: 'auto',
                        },
                        rest: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    {/* <CardPrice>{card.price}</CardPrice> */}
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
    </Container>
  )
}
