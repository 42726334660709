import React from 'react'
import styled from 'styled-components'
import SupportIconImage from '../images/services/support-icon.svg'
import ShieldIconImage from '../images/services/deliver.png'
import CustomizeIconImage from '../images/services/simple-icon.svg'
import tw from 'twin.macro'
import '../App.css'

const ThreeColumnContainer = styled.div`
  ${tw`m-3 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto`}
`
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}

  &:hover {
    transform: scale(1.05);
    transition-duration: 0.25s;
  }
`
const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-4 rounded`}
  .imageContainer {
    ${tw`text-center rounded-full p-4`}
    img {
      ${tw`w-9 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-lg`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-gray-500`}
  }
`

export const ServiceModal = () => {
  const cards = [
    {
      imageSrc: SupportIconImage,
      title: '24/7 Support',
      description: 'We are available 24/7 to hear your challenges',
    },
    {
      imageSrc: ShieldIconImage,
      title: 'On-Time Delivery',
      description: 'Deliveries are available all over India',
    },
    {
      imageSrc: CustomizeIconImage,
      title: 'Customer Satisfaction',
      description: 'We ensure the customer is satisfied at all costs',
    },
  ]
  const heading = 'We follow these.'
  const subheading = 'Our Values'
  return (
    <div className='font-poppins' id='services'>
      {subheading && (
        <h5 className='font-semi-bold text-gray-600 text-center mb-3'>{subheading}</h5>
      )}
      {heading && (
        <div className='text-4xl sm:text-4xl font-black tracking-wide text-center'>{heading}</div>
      )}
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className='imageContainer'>
                <img src={card.imageSrc} alt='' />
              </span>
              <span className='title'>{card.title}</span>
              <p className='description'>{card.description}</p>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </div>
  )
}
