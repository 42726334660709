import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const Container = styled.div`
  position: relative;
`

const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-8 items-center`}
`

const Column = styled.div`
  ${tw`w-full max-w-md mx-auto md:max-w-none md:mx-0`}
`

const ImageColumn = styled(Column)`
  ${tw`md:w-1/2 flex-shrink-0 relative`}
`

const Image = styled.img`
  ${tw`block w-full rounded-lg`}
`

const TextColumn = styled(Column)<{ textOnLeft: boolean }>`
  ${tw`md:w-1/2 mt-4 md:mt-0`}
  ${({ textOnLeft }) =>
    textOnLeft ? tw`md:mr-12 lg:mr-11 md:order-first` : tw`md:ml-12 lg:ml-11 md:order-last`}
`

const TextContent = styled.div`
  ${tw`lg:py-8 text-center md:text-left`}
`

const Subheading = styled.h5`
  ${tw`text-center md:text-left font-poppins`}
`

const Heading = styled.h2`
  ${tw`mt-4 font-black text-left text-2xl lg:text-3xl text-center md:text-left leading-tight font-poppins`}
`

const Description = styled.p`
  ${tw`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-poppins leading-relaxed text-gray-500`}
`

const CustomComponent = ({
  subheading = '',
  heading = '',
  description = '',
  imageSrc,
  textOnLeft = true,
}: {
  subheading?: string
  heading?: string
  description?: string
  imageSrc?: string
  textOnLeft?: boolean
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}

export default CustomComponent
