import React from 'react'
import './App.css'
import { Header } from './components/header'
import { Footer } from './components/footer'
import { Home } from './pages/home'

function App() {
  return (
    <div className='App' id='home'>
      <Header />
      <Home />
      <Footer />
    </div>
  )
}

export default App
