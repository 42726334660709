import React from 'react'
import MainFeature from './featureModal'
import serverRedundancyIllustrationImageSrc from '../images/features/alloy-pic-1.jpg'
import serverSecureIllustrationImageSrc from '../images/features/diecast.png'

export const Features = () => {
  return (
    <div>
      <div className='max-w-6xl mx-auto px-4 sm:px-6 mt-10'>
        {/* Section header */}
        <div className='max-w-3xl mx-auto text-center pb-12 md:pb-12'>
          {/* <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">About Us</div> */}
          <h1 className='text-xl pb-4'>Why Customers Love Us</h1>
          <p className='font-poppins text-base text-gray-600'>
            Since 2008, we have been a leading player in the zipper industry. Our commitment to
            quality and customer satisfaction has earned us the trust of countless clients
          </p>
        </div>

        <MainFeature
          subheading='Quality'
          heading='Premium Quality Zinc'
          description='We exclusively utilize the highest grade zamak 5 zinc in our production process to ensure that our zippers boast exceptional durability, strength, and a smooth, reliable performance'
          imageSrc={serverRedundancyIllustrationImageSrc}
        />
        <MainFeature
          subheading='Reliability'
          heading='Taiwan Machinery'
          description='At our manufacturing facility, we pride ourselves on delivering top-notch products with exceptional precision and finish. To achieve this level of excellence, we rely on cutting-edge machinery sourced from Taiwan'
          imageSrc={serverSecureIllustrationImageSrc}
          textOnLeft={false}
        />
      </div>
    </div>
  )
}
