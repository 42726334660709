import React from 'react'
import Slider from 'react-slick'
import image0 from '../images/denzip/auto-4.webp'
import image1 from '../images/denzip/green-slider.webp'
import image2 from '../images/denzip/sliders.webp'
import image4 from '../images/denzip/pullers.webp'
import image3 from '../images/denzip/potrait.webp'
import image5 from '../images/denzip/auto-5.webp'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { styled } from 'styled-components'
import tw from 'twin.macro'

const Sliders = styled.div`
  ${tw`h-[50vw] md:h-[40vw] xl:h-[30vw]`}
`

const Image = styled.img`
  ${tw`w-full h-full object-cover`}
`

export const ReactSlider = () => {
  return (
    <div className='pb-3'>
      <Slider
        dots={true}
        autoplay={false}
        arrows={true}
        infinite={false}
        slidesToScroll={1}
        slidesToShow={1}
      >
        <Sliders>
          <Image src={image0} />
        </Sliders>
        <Sliders>
          <Image src={image2} />
        </Sliders>
        <Sliders>
          <Image src={image3} />
        </Sliders>
        <Sliders>
          <Image src={image1} />
        </Sliders>
        <Sliders>
          <Image src={image4} />
        </Sliders>
        <Sliders>
          <Image src={image5} />
        </Sliders>
      </Slider>
    </div>
  )
}
