import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// import logo from '../images/denzip/Den_logo.png'
import '../App.css'

const StyledHeader = styled.div`
  margin: 2% 15%;
  align-items: center;
`

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DivRowFlex = styled.div`
  ${tw`flex flex-row items-center justify-center mx-20`}
`

const Heading = styled.div`
  font-size: 27px;
  font-family: 'AustinBold, Austin';
  font-weight: bold;
`

export const Border = styled.div`
  background-color: silver;
  height: 0.1px;
  margin: 2%;
`

const Div = styled.div`
  ${tw`font-poppins text-sm lg:text-base mx-2 cursor-pointer`}
`

export const Header = () => {
  const scrollToId = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <StyledHeader>
      <TopHeader>
        {/* <img src={logo} className='App-logo' alt='logo' /> */}
        <Heading>DEN ZIPPERS</Heading>
      </TopHeader>
      <Border />
      <DivRowFlex>
        <Div onClick={() => scrollToId('home')}>HOME</Div>
        <Div onClick={() => scrollToId('product')}>PRODUCTS</Div>
        <Div onClick={() => scrollToId('services')}>SERVICES</Div>
        <Div onClick={() => scrollToId('footer')}>CONTACT</Div>
      </DivRowFlex>
    </StyledHeader>
  )
}
